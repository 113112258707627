import React, {useEffect, useLayoutEffect} from 'react';
import {CenteredWrapper, Container, TopRow} from "../../../pages/styles";
import {Form as StyledForm} from "../../../pages/Auth/styles";
import {Url} from "finhelper";
import {connect} from "react-redux";
import {handleTitle} from "../../../actions/interface";
import {handleCloseModal, handleLoginModal, login} from '../../../actions/auth';

const Forbidden = (props) => {

  useLayoutEffect(() => {
    props.handleLoginModal();
  })

  useEffect(() => {
    props.handleTitle('Требуется авторизация');
    // window.scrollTo(0, 0); // Если требуется, можно раскомментировать
  }, []);

  const submitForm = (e) => {
    e.preventDefault();
    // Здесь логика отправки формы
  };

  const url = Url.getCurrentUrl();


  return (
    <Container style={{
      margin: url.indexOf('/cabinet') !== -1 ? '0 auto' : '20px auto 0 auto'
    }}>
      <CenteredWrapper>
        <StyledForm style={{
          maxWidth: '440px',
          margin: '0 auto'
        }} action="#" onSubmit={submitForm}>
          <TopRow flex>
          </TopRow>
        </StyledForm>
      </CenteredWrapper>
    </Container>
  );
};

const enhance = connect(
  (state) => ({
    auth: state.auth.auth,
    data: state.auth.data,
    i18n: state.i18n,
  }),
  { login, handleTitle, handleCloseModal, handleLoginModal }
);

export default enhance(Forbidden);