import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #fff;
  padding: 10px 24px 20px 24px;
  overflow: visible;
  min-height: 700px;
  box-shadow: rgb(38 38 38 / 4%) 0px 1px 2px, rgb(38 38 38 / 16%) 0px 4px 8px;
  border-radius: 16px;
  margin-bottom: 30px;
  transition: .3s all;

  &:hover {
    border-color: rgba(38, 38, 38, 0.16);
    box-shadow: rgb(38 38 38 / 4%) 0px 1px 2px, rgb(38 38 38 / 16%) 0px 8px 16px;
  }

  .sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }

  .sticky + .content {
    padding-top: 102px;
  }

  //&.grey {
  //  filter: grayscale(1);
  //}
`