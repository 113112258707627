import React from 'react';
import {connect} from "react-redux";
import {Modal, ModalClose, ModalContent, ModalFooter, ModalHeader} from "../../../interface/modal/Modal";
import {Button, Input} from "finform";
import {Form} from "../../../pages/components/Form";
import {Api} from "laravel-request";
import {Title2} from "../../../components/Text/styles";
import {QrCodeRow} from "./styles";
import {Row} from "#app/pages/styles";

class GoogleAuthModal extends Form {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      edited: false,
      loaded: false,
      img: '',
      type: 'svg',//without imagic
      secret: '',
      form: {
        password: '',
        code: '',
        secret: '',
      }
    }
  }

  handleShow() {
    if(this.props.type === 'enable')
    {
      this.prepare2Fa(() => {
        this.setState({
          show: true
        }, () => {
          
        });
      })
    }else{
      this.setState({
        show: true
      }, () => {
        
      });
    }
  }

  prepare2Fa(callback)
  {
    Api.post('auth', 'save-gauth')
      .call((response) => {
        this.setState((prv) => {
          if (typeof response.data.img === 'string' && response.data.img.includes('base64')) {
            prv.type = 'base64';
          }

          prv.img = response.data.img;
          prv.form.secret = response.data.secret;

          return prv;
        }, () => {
          if(typeof callback === 'function')
          {
            callback();
          }
        });
      })
  }

  submitForm()
  {
    this.submit(() => {
      Api.post('auth', 'set-gauth', this.state.form)
        .withValidateForm(this)
        .call((response) => {

          this.handleClose();

          if(typeof this.props.callback === 'function')
          {
            this.props.callback();
          }

        }, () => {
          this.setState((prv) => {
            prv.submitted = false;

            return prv;
          });
        })
    })
  }

  render()
  {
    return <Modal
      show={this.state.show}
      size={'extra-small'}
      trigger={<Button className='style4' id='enable-2fa' slim style={{margin: '20px auto 20px auto'}} onClick={() => {this.handleShow();}}>{this.props.type === 'enable' ? this.props.i18n.config.enable2Fa : this.props.i18n.config.disable2Fa}</Button>}
    >
      <ModalHeader>
        <Title2>2FA</Title2>
        <ModalClose id="close-icon" onClick={() => {this.handleClose()}}><div/></ModalClose>
      </ModalHeader>
      <ModalContent>
        {this.props.type === 'enable' && <Row>
          {this.state.type === 'svg' ?
            <QrCodeRow dangerouslySetInnerHTML={{__html: this.state.img}}/>
            :
            <img
              src={this.state.img}
              alt="qcode"
              style={this.state.loaded ? {margin: '0 auto'} : {margin: '0 auto', display: 'none'}}
              onLoad={() => this.setState({loaded: true})}/>
          }
        </Row>}
        <Row>
          <Input
            id='password-google'
            type="text"
            name="password"
            value={this.state.form.password}
            onChange={(e, { name, value }) =>
            {
              this.setState((prv) =>
              {
                prv.form.password = value;
                if(prv.formErrors)
                {
                  prv.formErrors.password = {};
                }

                return prv;
              });
            }}
            placeholder={this.props.i18n.config.currentPassword}
            errors={this.state.formErrors}
          />
        </Row>
        <Row>
          <Input
            id='code-google'
            type="text"
            name="code"
            value={this.state.form.code}
            onChange={(e, { name, value }) =>
            {
              this.setState((prv) =>
              {
                prv.form.code = value;
                if(prv.formErrors)
                {
                  prv.formErrors.code = {};
                }

                return prv;
              });
            }}
            placeholder={this.props.i18n.auth.fa}
            errors={this.state.formErrors}
          />
        </Row>
        <Row>
          <Button flex className='style2' id='2fa-submit' onClick={() => {
            this.submitForm();
          }}>{this.props.type === 'enable' ? this.props.i18n.config.enable2Fa : this.props.i18n.config.disable2Fa}</Button>
        </Row>
      </ModalContent>
      <ModalFooter>

      </ModalFooter>
    </Modal>;
  }
}

const enhance = connect(
  (state) => ({
    auth: state.auth.auth,
    i18n: state.i18n,
  },
  {}),
)

export default enhance(GoogleAuthModal);
