import React, {Suspense, useEffect, useLayoutEffect, useState} from 'react';
import {Navigate, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {connect} from 'react-redux';
import {getUser} from '../actions/auth';
import {Tracker, Url} from "finhelper";
import {NotifyContainer} from "notify-component";
import ErrorBoundary from '../interface/error/ErrorBoundary';
import NotFound from '../interface/error/NotFound';
import Forbidden from '../interface/error/Forbidden';
import {MainPage} from './LazyLoadedPages';
import CabinetRoutesWrapper from './CabinetRoutesWrapper';
import LandingLayout from './LandingLayout';

const AppRouter = ({ auth, authData, client, clientLoading, currencyLoading, accountLoading, formLoading, incomeTypesLoading, spendTypesLoading, tagsLoading, subcategoriesLoading, getUser, demo }) => {
  const location = useLocation();
  const [isActive, setActive] = useState();
  const navigate = useNavigate();
  const [redirected, setRedirected] = useState(false);

  const isLoaded = !clientLoading && !currencyLoading && !accountLoading && !formLoading && !incomeTypesLoading && !spendTypesLoading && !tagsLoading && !subcategoriesLoading;

  useLayoutEffect(() => {
    Tracker.init();
    getUser({
      callback: () => setActive(true),
      fallback: () => setActive(true)
    });
  }, [getUser]);

  useEffect(() => {
    Tracker.hit(location.pathname);
  }, [location]);

  useEffect(() => {
    if(auth && !redirected)
    {
      let url = Url.getCurrentUrl()
      setRedirected(true);
      if (auth && !url.includes('cabinet')) {
        navigate('cabinet/accounting/dashboard');
      }
    }
  }, [auth, navigate, redirected]);

  useEffect(() => {
    if(auth)
    {
      let url = Url.getCurrentUrl()
      if (auth && (url === '/cabinet' || url === '/cabinet/')) {
        navigate('cabinet/accounting/dashboard');
      }
    }
  }, [auth, navigate, redirected])

  if (typeof isActive === 'undefined') {
    return null;
  }

  return (
    <ErrorBoundary>
      <Routes>
        {auth ? (
          <Route path="/cabinet/*" element={<CabinetRoutesWrapper authData={authData} isLoaded={isLoaded} client={client} demo={demo} scenarioName={client?.scenario?.name} />} />
        ) : (
          <Route path="/cabinet" element={<Navigate to='/login' />} />
        )}
        <Route path="/" element={<LandingLayout />}>
          <Route path="/" element={<Suspense><MainPage /></Suspense>} />
          <Route path="/login" element={<Suspense><Forbidden /></Suspense>} />
          <Route path="*" element={<Suspense><NotFound /></Suspense>} />
        </Route>
      </Routes>
      <NotifyContainer />
    </ErrorBoundary>
  );
};

const enhance = connect(
  state => ({
    auth: state.auth.auth,
    authData: state.auth.data,
    client: state.interfaceComponents.client,
    clientLoading: state.interfaceComponents.clientLoading,
    currencyLoading: state.interfaceComponents.currencyLoading,
    accountLoading: state.interfaceComponents.accountLoading,
    incomeTypesLoading: state.interfaceComponents.incomeTypesLoading,
    spendTypesLoading: state.interfaceComponents.spendTypesLoading,
    tagsLoading: state.interfaceComponents.tagsLoading,
    subcategoriesLoading: state.interfaceComponents.subcategoriesLoading,
    formLoading: state.scenario.formLoading,
    demo: state.scenario.demo,
  }),
  { getUser }
);

export default enhance(AppRouter);