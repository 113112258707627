import React, {Fragment} from 'react';
import {handleCloseModal, register, requestRegister} from '../../actions/auth';
import {handleTitle} from '../../actions/interface';
import Form from "./Form";
import {connect} from "react-redux";
import {Container, GreyText, Text, Wrapper} from "./styles";
import {Button, Input} from "finform";
import {Row} from "#app/pages/styles";
import {NotifyManager} from "notify-component";
import {Caption} from "#app/components/Text/styles";
import LocalStorageHelper from "#app/helpers/Cache/LocalStorageHelper";

class Register extends Form {
  constructor(props) {
    super(props);

    let email = localStorage.getItem(LocalStorageHelper.AUTH_EMAIL) || '';

    this.state = {
      ...this.state,
      form: {
        email: email,
        password: '',
        password_confirmation: '',
        captchaValue: '',
        code: '',
        role: 'client',
        promo_code: '',
        form: 'client'
      },
      formErrors: {},
      code: false,
      submitted: false,
      checked: false,
      resendCodeTimer: 0, // New state variable for the timer
    };

    this.timerInterval = null; // To store the interval ID
  }

  componentWillUnmount() {
    // Clear the timer when the component unmounts to prevent memory leaks
    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  }

  startResendCodeTimer = () => {
    this.setState({ resendCodeTimer: 60 }, () => {
      this.timerInterval = setInterval(() => {
        this.setState((prevState) => {
          if (prevState.resendCodeTimer <= 1) {
            clearInterval(this.timerInterval);
            this.timerInterval = null;
            return { resendCodeTimer: 0 };
          }
          return { resendCodeTimer: prevState.resendCodeTimer - 1 };
        });
      }, 1000);
    });
  }

  submitRequestRegisterForm() {
    this.setState({
      submitted: true
    }, () => {
      this.props.requestRegister({
        form: this.state.form,
        success: (response, status, xhr) => {
          this.setState({
            code: true,
            submitted: false
          });

          // Start the resend code timer
          this.startResendCodeTimer();
        },
        error: (xhr) => {
          this.setState({
            submitted: false,
          });
        },
        obj: this
      });
    });
  }

  submitRegisterForm() {
    this.setState({
      submitted: true
    }, () => {
      this.props.register({
        form: this.state.form,
        success: (response, status, xhr) => {

          var header = xhr.getResponseHeader('Authorization');

          if(header)
          {
            localStorage.setItem(LocalStorageHelper.API_TOKEN, header);
            this.props.handleCloseModal();

            this.setState({
              form: {
                email: '',
                password: '',
                password_confirmation: '',
                captchaValue: '',
                code: '',
                role: 'client',
                promo_code: '',
              },
              submitted: false,
              code: false, // Reset the code state
              resendCodeTimer: 0, // Reset the timer
            }, () => {
              if (typeof this.props.callback === 'function') {
                this.props.callback();
              }
            });
          } else {
            NotifyManager.error('Ошибка авторизации');
          }
        },
        error: (xhr) => {
          this.setState({
            submitted: false,
          });
        },
        obj: this
      });
    });
  }

  handleEnter(e) {
    if (e.key === 'Enter' || e.keyCode === 13) {
      this.submitForm();
    }
  }

  submitForm() {
    if (this.state.code) {
      this.submitRegisterForm();
    } else {
      this.submitRequestRegisterForm();
    }
  }

  resendCode = (e) => {
    e.preventDefault();

    if(this.state.resendCodeTimer > 0 || this.state.submitted)
    {
      return;
    }

    if (this.state.resendCodeTimer === 0) {
      this.submitRequestRegisterForm();
    }
  }

  renderForm() {
    if (this.state.code) {
      return this.renderCodeForm();
    } else {
      return this.renderRegisterForm();
    }
  }

  renderCodeForm() {
    const { resendCodeTimer } = this.state;

    return (
      <Wrapper>
        <Container>
          <Fragment>
            <Text>{this.props.i18n.auth.codeAdvise} {this.state.form.email}</Text>
            <Row>
              <Input
                id='register-code'
                type="text"
                name="code"
                value={this.state.form.code}
                onKeyPress={(e) => {
                  this.handleEnter(e)
                }}
                onChange={(e, { name, value }) => {
                  this.setState((prevState) => {
                    prevState.form.code = value;
                    prevState.formErrors.code = {};

                    return { ...prevState };
                  });
                }}
                placeholder={this.props.i18n.auth.code}
                errors={this.state.formErrors}
              />
            </Row>
          </Fragment>
          <a style={{cursor: 'pointer'}} onClick={this.resendCode}>{resendCodeTimer > 0
            ? `${this.props.i18n.auth.resendCode} (${resendCodeTimer} секунд)`
            : this.props.i18n.auth.resendCodeButton}</a>
          <GreyText>{this.props.i18n.auth.issue}</GreyText>
          <Row>
            <Button
              className="style2"
              flex
              id='register-submit'
              onClick={() => {
                this.submitForm()
              }}
              disabled={this.state.submitted}
            >
              {this.props.i18n.auth.confirm}
            </Button>
          </Row>
        </Container>
      </Wrapper>
    );
  }

  renderRegisterForm() {
    return (
      <Wrapper>
        <Container>
          <Row>
            <Input
              id='register-email'
              type="text"
              name="email"
              autoComplete='on'
              value={this.state.form.email}
              onKeyPress={(e) => {
                this.handleEnter(e)
              }}
              onChange={(e, { name, value }) => {
                localStorage.setItem('auth_email', value)
                this.setState((prevState) => {
                  prevState.form.email = value;
                  if (prevState.formErrors) {
                    prevState.formErrors.email = {};
                  }

                  return { ...prevState };
                });
              }}
              placeholder={'Email'}
              errors={this.state.formErrors}
            />
          </Row>
          <Row>
            <Input
              id='register-password'
              type="password"
              name="password"
              value={this.state.form.password}
              onKeyPress={(e) => {
                this.handleEnter(e)
              }}
              onChange={(e, { name, value }) => {
                this.setState((prevState) => {
                  prevState.form.password = value;
                  if (prevState.formErrors) {
                    prevState.formErrors.password = {};
                  }

                  return { ...prevState };
                });
              }}
              placeholder={this.props.i18n.auth.password}
              errors={this.state.formErrors}
            />
          </Row>
          <Row>
            <Input
              id='register-password_confirmation'
              type="password"
              name="password_confirmation"
              value={this.state.form.password_confirmation}
              onKeyPress={(e) => {
                this.handleEnter(e)
              }}
              onChange={(e, { name, value }) => {
                this.setState((prevState) => {
                  prevState.form.password_confirmation = value;
                  if (prevState.formErrors) {
                    prevState.formErrors.password_confirmation = {};
                  }

                  return { ...prevState };
                });
              }}
              placeholder={this.props.i18n.auth.passwordRepeat}
              errors={this.state.formErrors}
            />
          </Row>
          <Row>
            <Input
              id='register-promo_code'
              type="text"
              name="promo_code"
              value={this.state.form.promo_code}
              onKeyPress={(e) => {
                this.handleEnter(e)
              }}
              onChange={(e, { name, value }) => {
                this.setState((prevState) => {
                  prevState.form.promo_code = value;
                  if (prevState.formErrors) {
                    prevState.formErrors.promo_code = {};
                  }
                  return { ...prevState };
                });
              }}
              placeholder={this.props.i18n.auth.promoCode}
              errors={this.state.formErrors}
            />
          </Row>
          <Row style={{ margin: '10px 0' }}>
            <Caption>
              Регистрируясь на сайте Вы соглашаетесь с{' '}
              <a target="_blank" href="/agreement">Пользовательским соглашением</a> и{' '}
              <a target="_blank" href="/policy">Политикой по обработке персональных данных</a>.
            </Caption>
          </Row>
          <Row>
            <Button
              className="style2"
              flex
              id='register-submit'
              onClick={() => {
                this.submitForm();
              }}
              disabled={this.state.submitted || this.state.resendCodeTimer > 0}
            >
              {this.props.i18n.auth.toRegister}
            </Button>
          </Row>
          {this.state.resendCodeTimer > 0 && (
            <Row style={{ marginTop: '10px' }}>
              <GreyText>
                Вы можете повторно отправить код через {this.state.resendCodeTimer} секунд.
              </GreyText>
            </Row>
          )}
        </Container>
      </Wrapper>
    );
  }

  render() {
    return this.renderForm();
  }
}

const enhance = connect(
  state => ({
    i18n: state.i18n,
  }),
  { register, requestRegister, handleTitle, handleCloseModal }
);

export default enhance(Register);