import styled from 'styled-components'


export const Block = styled.div`
    border: 1px solid #D7DBE0;
    border-radius: 12px;
    display: flex;
    flex: 1;
    position: relative;
    
    img{
      width: 56px;
      height: 56px;
      margin-left: 24px;
      margin-top: 24px;
    }
`
export const QrCodeRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    position: relative;
    align-items: center;
    box-sizing: border-box;    
    
    svg{
      margin: 0 auto;
    }
`