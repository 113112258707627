import React, {Fragment, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Container} from '../../styles';
import {getUser, postUser} from "../../../actions/auth";
import {finishScenario, getFormData, moveScenario} from "../../../actions/scenario";
import {getPlan, setLoading} from "../../../actions/plan";
import {Row} from "#app/pages/styles";
import {getClients, getUserAccounts, handleClient, handleTitle} from "../../../actions/interface";
import {Account, ClientConstants} from "finhelper";
import {Body, Title3} from "../../../components/Text/styles";
import {Block} from "./styles";
import GoogleAuthModal from "./GoogleAuthModal";
import {Checkbox, Search} from "finform";
import {Api} from "laravel-request";
import {NotifyManager} from "notify-component";
import {Wrapper} from "#app/components/ColoredWrapper/styles";

let google = require('./../../../assets/images/google.png');

export const isManager = (data) => {
  return data && data.role && (ClientConstants.MANAGER_GROUP.indexOf(data.role) !== -1);
}

const Security = (props) => {
  const [form, setForm] = useState({});

  useEffect(() => {
    props.handleTitle('Безопасность');
    setForm((prevForm) => {
      props.currencies.forEach((item) => {
        if (item.id === props.client.currency_id) {
          prevForm.currency = item;
          prevForm.currency_id = item.id;
          prevForm.currency_search = item.name;
        }
      });
      return { ...prevForm };
    });
  }, [props]);

  const loadUserData = () => {
    props.getUser({
      callback: () => {
        setForm((prevForm) => {
          props.currencies.forEach((item) => {
            if (item.id === props.client.currency_id) {
              prevForm.currency = item;
              prevForm.currency_id = item.id;
              prevForm.currency_search = item.name;
            }
          });
          return { ...prevForm };
        });
      }
    });
  };

  return (
    <Fragment>
      <Container style={{ width: '100%' }}>
        <Row flex>
          <Wrapper style={{ minHeight: '260px', marginRight: '14px', flex: '1' }}>
            <Title3 style={{ margin: '10px 0' }}>{props.i18n.config.security}</Title3>
            <Row>
              <Block style={{ marginRight: '14px', minHeight: '168px' }}>
                <div style={{ flexBasis: '104px' }}>
                  <img src={google} alt='' />
                </div>
                <div style={{ flex: '1', paddingRight: '30px' }}>
                  <Title3 style={{ margin: '20px 0 5px 0' }}>Google Authenticator</Title3>
                  <Body>{props.i18n.config.securityGoogle}</Body>
                  {props.client.gauth_trigger ? (
                    <GoogleAuthModal i18n={props.i18n} type={'disable'} callback={loadUserData} />
                  ) : (
                    <GoogleAuthModal i18n={props.i18n} type={'enable'} callback={loadUserData} />
                  )}
                </div>
              </Block>
            </Row>
          </Wrapper>
          <Wrapper style={{ minHeight: '260px', marginLeft: '14px', flex: '1' }}>
            <Title3 style={{ margin: '10px 0' }}>{props.i18n.config.config}</Title3>
            <Row>
              <Search
                default={props.i18n.config.mainCurrency}
                placeholder={props.i18n.config.mainCurrency}
                id={'currency-search'}
                name={'currency_id'}
                disabled={false}
                selected={form.currency ? form.currency.name : ''}
                search={form.currency_search}
                onSearch={(search) => {
                  setForm((prevForm) => {
                    prevForm.currency_search = search;
                    let searchItem = null;
                    props.currencies.forEach((item) => {
                      if (item.name === search) {
                        searchItem = item;
                      }
                    });

                    if (searchItem) {
                      prevForm.currency = searchItem;
                      prevForm.currency_id = searchItem.id;
                    } else {
                      prevForm.currency = null;
                      prevForm.currency_id = '';
                    }

                    return { ...prevForm };
                  });
                }}
                handle={(selectedItem) => {
                  if(selectedItem)
                  {
                    setForm((prevForm) => {
                      prevForm.currency = selectedItem;
                      prevForm.currency_id = selectedItem.id;
                      return { ...prevForm };
                    });
                    Api.putArg('profile', 'field', props.client.id, {
                      user_id: props.client.id,
                      currency_id: selectedItem.id
                    }).call(() => {
                      let client = { ...props.client };
                      client.currency_id = selectedItem.id;
                      props.handleClient(client);
                      props.setLoading(true);
                      props.getPlan();
                      props.setLoading(false);
                      NotifyManager.info('Успешно', props.i18n.config.currencySaved);
                    });
                  }else{
                    setForm((prevForm) => {
                      prevForm.currency = null;
                      prevForm.currency_id = '';
                      return { ...prevForm };
                    });
                  }
                }}
                items={Account.getCurrencyFiltered(null, props.currencies)}
                errors={[]}
              />
            </Row>
          </Wrapper>
        </Row>
      </Container>
      {!isManager(props.data) && (
        <Container style={{ width: '100%' }}>
          <Row flex>
            <Wrapper style={{ minHeight: '260px', flex: '1' }}>
              <Title3 style={{ margin: '10px 0px 6px 0' }}>{props.i18n.config.privateConfig}</Title3>
              <Row>
                <Checkbox
                  style={{ padding: '0 0 0 10px' }}
                  textStyle={{color: '#000'}}
                  text={props.i18n.config.adminShow}
                  checked={props.client.is_visible_spend}
                  toggleCallback={() => {
                    Api.putArg('profile', 'field', props.client.id, {
                      user_id: props.client.id,
                      is_visible_spend: !props.client.is_visible_spend
                    }).call(() => {
                      let client = { ...props.client };
                      client.is_visible_spend = !props.client.is_visible_spend;
                      props.handleClient(client);
                    });
                  }}
                />
              </Row>
              <Row>
                <Checkbox
                  style={{ padding: '0 0 0 10px' }}
                  textStyle={{color: '#000'}}
                  text={props.i18n.config.adminOperation}
                  checked={!props.client.is_allow_api_operation}
                  toggleCallback={() => {
                    Api.putArg('profile', 'field', props.client.id, {
                      user_id: props.client.id,
                      is_allow_api_operation: !props.client.is_allow_api_operation
                    }).call(() => {
                      let client = { ...props.client };
                      client.is_allow_api_operation = !props.client.is_allow_api_operation;
                      props.handleClient(client);
                    });
                  }}
                />
              </Row>
            </Wrapper>
          </Row>
        </Container>
      )}
    </Fragment>
  );
}

const enhance = connect(
  state => ({
    auth: state.auth.auth,
    data: state.auth.data,
    client: state.interfaceComponents.client,
    accounts: state.interfaceComponents.accounts,
    currencies: state.interfaceComponents.currencies,
    formData: state.scenario.data,
    i18n: state.i18n,
  }),
  { getUser, postUser, moveScenario, finishScenario, handleTitle, getClients, getUserAccounts, getFormData, handleClient, setLoading, getPlan }
);

export default enhance(Security);